.task-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    background-color: #395B64;
    margin:auto;
    width:70vw;
    row-gap: 10px;
    align-items: center;
}

.task-form>input {
    line-height: 2.5rem;
    font-size: 2rem;
    text-indent: 10px;
    padding:0.5rem;
    color: #000;
    outline:none;
    border: 2px solid #2C3333;
    background-color: #E7F6F2;
}

.task-form>button {
    width:20%;
    font-size: 2rem;
    padding: 0.5rem;
    border: 2px solid #2C3333;
    background-color: #E7F6F2;
}

.task-form>button:hover {
    border: 2px solid #E7F6F2;
    background-color: #2C3333;
    color: #fff;
    font-weight: 400;
}

.task-form>input:focus {
    border: 2px solid #E7F6F2;
    background-color: #2C3333;
    color: #fff;
    font-weight: 400;
}

@media screen and (max-width:1100px) {
    .task-form>input {
        line-height: 2.3rem;
        font-size: 1.8rem;
        text-indent: 9px;
    }
    
    .task-form>button {
        width:25%;
        font-size: 1.8rem;
    }
}
@media screen and (max-width:800px) {
    .task-form>input {
        line-height: 2rem;
        font-size: 1.6rem;
    }
    .task-form>button {
        width:30%;
        font-size: 1.6rem;
    }
}
@media screen and (max-width:650px) {
    .task-form>input {
        line-height: 1.8rem;
        font-size: 1.4rem;
        text-indent: 8px;
    }
    .task-form>button {
        width:30%;
        font-size: 1.4rem;
    }
}
@media screen and (max-width:500px) {
    .task-form>input {
        line-height: 1.6rem;
        font-size: 1.2rem;
    }
    .task-form>button {
        width:40%;
        font-size: 1.2rem;
    }
}

@media screen and (max-width:450px) {
    .task-form {
        width:80vw;
    }
}
@media screen and (max-width:400px) {
    .task-form>input {
        line-height: 1.4rem;
        font-size: 1rem;
        text-indent: 6px;
        padding:0.3rem;
        
    }
    .task-form>button {
        width:40%;
        font-size: 1rem;
        padding: 0.3rem;
    }
}

@media screen and (max-width:350px) {
    .task-form>input {
        line-height: 1.2rem;
        font-size: 0.8rem;
    }
    .task-form>button {
        width:45%;
        font-size: 0.8rem;
    }
}