.task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #A5C9CA;
    width:70vw;
    height:50vh;
    margin:auto;
    overflow-y: auto;
}

.no-task {
    margin-block: auto;
    color:#395B64;
}

.main-header {
    text-align: center;
    margin-block: 1rem;
}

@media screen and (max-width:1100px) {
    .no-task {
        font-size: 20px;
    }

    .main-header {
        font-size: 28px;
    }
}
@media screen and (max-width:650px) {
    .no-task {
        font-size: 17px;
    }
    .main-header {
        font-size: 24px;
        margin-block: 0.8rem;
    }
}

@media screen and (max-width:450px) {
    .no-task {
        font-size: 15px;
    }
    .task-container {
        width:80vw;
    }
    .main-header {
        font-size: 22px;
        margin-block: 0.6rem;
    }
}
@media screen and (max-width:350px) {
    .no-task {
        font-size: 15px;
    }
    .main-header {
        font-size: 20px;
        margin-block: 0.5rem;
    }
}