.task-item {
    display: flex;
    justify-content: space-evenly;
    padding:1rem;
    width:100%
}

.task-item>p {
    width: 70%;
    font-size: 25px;
    background-color: #395B64 ;
    padding: 1rem;
    user-select:none;
}

.task-item>button {
    width:25%;
    background-color: #E7F6F2;
    border: none;
}

.task-item-delete-icon {
    font-size: 25px;
}

.task-item>button:hover {
    background-color: #395B64;
    color: #E7F6F2;
}

